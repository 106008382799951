// @flow
import * as React from 'react';
import {FC, CSSProperties, useState, useEffect, useCallback, BaseSyntheticEvent} from 'react';
import {Form} from "react-bootstrap";

type IInputDadataInnProps = {
  className?: string
  style?: CSSProperties
  setFormData: (val: any) => void
};
export const InputDadataInn: FC<IInputDadataInnProps> = ({setFormData}) => {
  const [value, setValue] = useState('')
  const onChangeHandler = useCallback((e: BaseSyntheticEvent) => {
    setValue(e.target.value.replace(/[^0-9]/ig,''))
  }, [])
  
  const checkInn = useCallback(async () => {
    if (parseInt(value) > 8) {
      const data = await fetch(`/api/dadata?inn=${value}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }).then(async (res) => await res.json())
      console.log('data', data.suggestions)
      setFormData(data.suggestions[0])
    }
  }, [value, setFormData])
  
  useEffect(() => {
    const time = setTimeout(checkInn, 800)
    
    return () => {
      clearTimeout(time)
    }
  }, [value])
  
  return (
    <div className="form-wrap__input-wrap">
      <label>
        <span className="form-wrap__title">ИНН</span>
        <Form.Control className="form-wrap__input" name="inn" value={value} onChange={onChangeHandler}/>
      </label>
    </div>
  );
};