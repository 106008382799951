// @flow
"use client";
import * as React from "react";
import { FC, CSSProperties, useRef, useState, useEffect } from "react";

import styles from "../scss/event-video-player.module.scss";
import { RunetEvent } from "@runetid/js-sdk";

type IEventVideoPlayerProps = {
  className?: string
  style?: CSSProperties
  event: RunetEvent;
  played: string | null,
  setPlayed: (val: string | null) => void
};

export const EventVideoPlayer: FC<IEventVideoPlayerProps> = ({
                                                               event,
                                                               played: elsePlayed,
                                                               setPlayed: setElsePlayed,
                                                             }) => {
  const videoRef = useRef<any | null>(null);
  const [played, setPlayed] = useState(false);

  const onVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setElsePlayed("video");
    } else {
      setElsePlayed(null);
      videoRef.current.pause();
    }
    setPlayed(prev => !prev);
  };

  useEffect(() => {
    if (elsePlayed !== "video" && played) {
      videoRef.current.pause();
      setPlayed(false);
    }
  }, [elsePlayed, played]);

  return (
    <div
      className={styles.event_video_player}
      onClick={onVideoClick}
    >
      <video id="my-player" className="video-js vjs-theme-city" controls={false}
             ref={videoRef} loop autoPlay
             muted={true}>
        <source src={event.video_source} />
      </video>
      <div className={styles.btn}>{!played ?
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"
             fill="white" className="bi bi-play"
             viewBox="0 0 16 16">
          <path
            d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
        </svg> :
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"
             fill="white" className="bi bi-pause"
             viewBox="0 0 16 16">
          <path
            d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5" />
        </svg>}</div>
    </div>
  );
};
