// @flow
import * as React from "react";
import {
  FC,
  CSSProperties,
  useCallback,
  useMemo,
  BaseSyntheticEvent,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { CloudPayments } from "../../components/pay/cloudpayments";
import { Loading } from "../../components/Loading";
import toast from "react-hot-toast";
import { saveBlobAsFile } from "../../utils/saveBlobAsFile";
import { IRegistredUser } from "../../components/event/registerOnEvent";
import { usePay } from "../../hooks/usePay";
import { OrderItem, RunetEvent, User } from "@runetid/js-sdk";

type IRegisterEventActionsBtnProps = {
  className?: string
  style?: CSSProperties
  allreadyAddedUsers: OrderItem[]
  user: User | null
  users: IRegistredUser[]
  event: RunetEvent
  setOrder_Id: React.Dispatch<React.SetStateAction<number | null>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  setPaymentType: React.Dispatch<React.SetStateAction<string | null>>
  paymentType: string | null
  order_id: number | null

  setFD: React.Dispatch<React.SetStateAction<Object>>
  fd: Object
  delivery_type: number
  handleClose(): void
  setUsers: React.Dispatch<React.SetStateAction<IRegistredUser[]>>
  setSummaryWithPromo: React.Dispatch<React.SetStateAction<number | null>>
  step: number
  disabled: boolean
  summaryWithPromo: number | null
  promo: string
  validPromo: boolean
  ref: React.MutableRefObject<null>
};
export const RegisterEventActionsBtn: FC<IRegisterEventActionsBtnProps> = ({
                                                                             user,
                                                                             allreadyAddedUsers,
                                                                             users,
                                                                             event,
                                                                             setOrder_Id,
                                                                             setStep,
                                                                             setPaymentType,
                                                                             paymentType,
                                                                             order_id,
                                                                             setFD,
                                                                             fd,
                                                                             delivery_type,
                                                                             handleClose,
                                                                             setUsers,
                                                                             setSummaryWithPromo,
                                                                             step,
                                                                             disabled,
                                                                             summaryWithPromo,
                                                                             promo,
                                                                             validPromo,
                                                                             ref,
                                                                           }) => {
  const [createLoading, setCreateLoading] = useState(false);
  const pay = usePay();
  const payer_id = React.useMemo(() => user?.id, [user]);
  const filtredAllready = useMemo(
    () => users.filter((user) => !user.caret_id),
    [allreadyAddedUsers, users],
  );
  const cantContinue = useMemo(() => !users.every(item => item.id), [users]);

  const goToNextStepAndRegisterUser = useCallback(async () => {

    await pay.order.butchUpdate({ data: users, eventId: event.id });

    try {
      const err = [];
      if (filtredAllready.length) {
        for (const item of filtredAllready) {


          const data = {
            payer_id,
            product_id: item.type ? Number(item.type) : null,
            owner_id: item.id,
          };
          if (!data.payer_id || !data.product_id || !data.owner_id) {
            err.push(
              `Пользователя ${item.login} не удалось зарегистрировать попробуйте позднее`,
            );
            continue;
          }
          try {
            const res = data.payer_id &&
              data.product_id &&
              //@ts-ignore
              data.owner_id && await pay.order.add(data);
            setOrder_Id(res.order_id);
          } catch (e) {
            err.push(
              `Пользователя ${item.login} не удалось зарегистрировать попробуйте позднее`,
            );
          }
        }
      }
      err.length && toast.error(err.join("\n"));
    } finally {
      setStep(1);
      setPaymentType("1");
    }
  }, [filtredAllready, payer_id, pay.order]);

  const nextStep = useCallback(async () => {
    setStep((prev) => {
      if (paymentType) {
        switch (paymentType) {
          case "1": {
            return prev;
          }
          case "2": {
            return prev + 1;
          }
        }
      }
      return prev + 1;
    });
  }, [paymentType, filtredAllready]);

  const createReport = async (e: BaseSyntheticEvent) => {
    try {
      setCreateLoading(true);
      //@ts-ignore
      const inputs = document.querySelector("form#regData").querySelectorAll("input[name]") as NodeList[];
      for (const item of inputs) {
        //@ts-ignore
        fd[item.getAttribute("name")] = item.value;
      }
      setFD((prev) => ({ ...prev, order_id, delivery_type }));
      const newFD = { ...fd, order_id, delivery_type };
      const data = order_id && (await pay.process.juridical(order_id, newFD));
      const res = order_id && (await pay.process.bill(data.id, data.hash));
      const blob = res && (await res.blob());
      blob && saveBlobAsFile(blob, `Счет №${order_id}.pdf`);
    } finally {
      setCreateLoading(false);
      handleClose();
    }
  };

  const endProcess = async () => {
    try {
      setCreateLoading(true);
      order_id && await pay.process.endPayment(order_id);
      toast("Спасибо! Ваш заказ оформлен");
    } catch (e) {
      toast.error("Что то пош9ло не так заказ не удалось завершить!");
    } finally {
      setStep(1);
      setPaymentType("1");
      setUsers([]);
      setSummaryWithPromo(null);
      setCreateLoading(false);
      handleClose();
    }
  };

  switch (step) {
    case 0: {
      return (<Button
        className="w-100"
        variant="primary"
        onClick={goToNextStepAndRegisterUser}
        disabled={disabled || cantContinue}
      >
        Далее
      </Button>);
    }
    case 1: {
      switch (paymentType) {
        case "1": {
          return <>
            {summaryWithPromo !== null && summaryWithPromo > 0 ?
              <>
                {order_id &&
                  <CloudPayments orderId={order_id} disabled={disabled} title={'Далее'}
                                 beforeSuccessCallback={() => {
                                 }} />}
                <div className="redirect">
                  Вы будете направлены на страницу оплаты
                </div>
              </>

              :
              <>
                {promo.length && validPromo ?
                  <>
                    <Button
                      className="w-100"
                      variant="primary"
                      onClick={endProcess}
                      disabled={disabled || cantContinue}
                    >
                      Завершить заказ
                    </Button>
                    <div className="redirect">
                      Вы завершите заказ
                    </div>
                  </> : <>
                    {order_id &&
                      <CloudPayments orderId={order_id} disabled={disabled} title={'Далее'}
                                     beforeSuccessCallback={() => {
                                     }} />}
                    <div className="redirect">
                      Вы будете направлены на страницу оплаты
                    </div>
                  </>
                }
              </>

            }

          </>;
        }
        default: {
          return (<>
            <Button
              className="w-100"
              variant="primary"
              onClick={nextStep}
              disabled={disabled}
            >
              Далее
            </Button>
            <div className="redirect">
              Вы будете направлены на страницу для указания данных организации
            </div>
          </>);
        }
      }
    }
    case 2: {
      switch (createLoading) {
        case true: {
          return <Button className="w-100" variant="primary"
                         style={{ position: "relative" }} disabled={true}>
            Сформировать счет
            <div
              style={{
                position: "absolute",
                display: "inline-flex",
                top: 10,
                right: 10,
              }}
            >
              <Loading />
            </div>

          </Button>;
        }
        case false: {
          return (
            <>
              {summaryWithPromo === null
                ? <Button className="w-100" variant="primary"
                          onClick={createReport}>
                  Сформировать счет
                </Button>
                : summaryWithPromo === 0 ?
                  <>
                    <Button
                      className="w-100"
                      variant="primary"
                      onClick={endProcess}
                      disabled={disabled || cantContinue}
                    >
                      Завершить заказ
                    </Button>
                    <div className="redirect">
                      Вы завершите заказ
                    </div>
                  </> : <Button className="w-100" variant="primary"
                                onClick={createReport}>
                    Сформировать счет
                  </Button>
              }
            </>
          );
        }
      }
    }
  }
};
