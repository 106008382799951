// @flow
import * as React from 'react';
import {FC, CSSProperties, useState, useEffect, useCallback} from 'react';
import {Form} from "react-bootstrap";
import {Loading} from "../../../components/Loading";
import {usePay} from "../../../hooks/usePay";

type IPromoProps = {
  className?: string
  style?: CSSProperties
  title?: string
  value: string
  setValue: React.Dispatch<string>
  order_id: number | null
  setSummaryWithPromo: React.Dispatch<React.SetStateAction<number | null>>
  setValid: React.Dispatch<React.SetStateAction<boolean>>
  valid: boolean
};
export const Promo: FC<IPromoProps> = ({
                                         title = 'Промокод',
                                         value,
                                         setValue,
                                         valid,
                                         setValid,
                                         order_id,
                                         setSummaryWithPromo}) => {
  const [promo, setPromo] = useState('')
  const pay = usePay()
  const [loading, setLoading] = useState(false)

  const checkPromo = useCallback(async () => {
    try {
      setLoading(true)
      if (promo.length && order_id) {
        const {data} = await pay.coupon.activate(promo, order_id)
        setSummaryWithPromo(data.total_paid)
        setValid(true)
        return
      }
      setValid(false)
    } catch (e) {
      setValid(false)
      setSummaryWithPromo(null)
    } finally {
      setValue(promo)
      setLoading(false)
    }
  }, [promo])

  useEffect(() => {
    const time = setTimeout(checkPromo, 500)

    return () => {
      clearTimeout(time)
    }
  }, [promo]);

  return (
    <div className="form-wrap__input-wrap">
      <label style={{position: 'relative'}}>
        <span className="form-wrap__title">{title}</span>
        <Form.Control
          className="form-wrap__input"
          onChange={(e) => setPromo(e.target.value)}
          value={promo}
          isValid={valid}
          isInvalid={!valid}
        />
        {/*<Form.Control.Feedback type="invalid" tooltip>*/}
        {/*  Нет такого промокода*/}
        {/*</Form.Control.Feedback>*/}
        {/*<Form.Control.Feedback type="valid" tooltip>*/}
        {/*  Промокод активирован*/}
        {/*</Form.Control.Feedback>*/}
        {loading ? <div style={{position: 'absolute', top: 45, right: 2}}><Loading/></div> : <></>}

        <span className="form-wrap__caption">Если у вас есть промокод, укажите его поле для активации скидки. Промокод активируется сразу после его ввода в поле. После активации завершите заказ. </span>
      </label>
    </div>
  );
};
