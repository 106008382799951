import { BaseSyntheticEvent, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import { SwiperSlide } from "swiper/react";
import { Hall } from "@runetid/js-sdk";

type HallItemProps = {
  hall: Hall
  active: boolean
  onClick: (hall: Hall) => (e: BaseSyntheticEvent) => void
  style?: CSSProperties
}

export const HallItem = ({ hall, active, onClick, style }: HallItemProps) => {
  return (
    <SwiperSlide className="sProgram__slide" style={style}>
      <Button
        variant="outline-secondary"
        className={`new ${active ? "active" : ""}`}
        onClick={onClick(hall)}
      >
        {hall.title}
      </Button>
    </SwiperSlide>
  );
};
