// @flow
"use client";
import * as React from "react";
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import moment from "moment";
import { User } from "../../../components/event/registerOnEvent/User";
import { useProduct } from "../../../hooks/useProduct";
import { usePay } from "../../../hooks/usePay";
import { Toaster } from "react-hot-toast";
import { useRouter } from "next/navigation";

import { Loading } from "../../../components/Loading";
import { Promo } from "../../../components/event/registerOnEvent/Promo";
import {
  RegistrationToEventFormForIE,
} from "../../../components/event/registerOnEvent/RegistrationToEventFormForIE";
import {
  RegisterEventActionsBtn,
} from "../../../components/event/RegisterEventActionsBtn";
import {
  OrderItem,
  Product,
  RunetEvent,
  User as UserModel,
} from "@runetid/js-sdk";

type IRegisterOnEventProps = {
  className?: string;
  style?: CSSProperties;
  event: RunetEvent;
  user: UserModel | null;
};

export interface IRegistredUser {
  login: string;
  type: string | null;
  flat: string | null;
  id: number | null;
  runet_id: number | null;
  caret_id: number | null;
}

export const RegisterOnEvent: FC<IRegisterOnEventProps> = ({ event, user }) => {
  const ref = useRef(null);

  const router = useRouter();
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<IRegistredUser[]>([]);
  const [allreadyAddedUsers, setAllreadyAddedUsers] = useState<OrderItem[]>(
    [],
  );
  const [disabled, setDisabled] = useState(false);
  const [typeList, setTypeList] = useState<Product[]>([]);
  const defaultUser = useMemo(
    () =>
      ({
        login: "",
        type: String(typeList?.[0]?.id),
        flat: null,
        id: null,
        runet_id: null,
        caret_id: null,
      } as IRegistredUser),
    [typeList],
  );
  const [paymentType, setPaymentType] = useState<string | null>(null);
  const [promo, setPromo] = useState("");
  const [step, setStep] = useState(0);
  const [delivery_type, setDeliveryType] = useState(2);
  const [order_id, setOrder_Id] = useState<number | null>(null);
  const [fd, setFD] = useState<Object>({});
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [validPromo, setValidPromo] = useState(false);

  const [summaryWithPromo, setSummaryWithPromo] = useState<number | null>(null);
  const pay = usePay();
  const { getListTypes, getFilteredProducts } = useProduct();

  const summary = useMemo(
    () =>
      users.length
        ? users
          .map(
            (item) =>
              typeList.find((row) => row.id === Number(item.type))
                ?.current_price.cost ?? 0,
          )
          .reduce((a, b) => a + b)
        : 0,
    [JSON.stringify(users), typeList],
  );

  const backStep = () => setStep((prev) => prev - 1);

  const getUserList = useCallback(async () => {
    try {
      setLoadingUsers(true);
      const data = user && (await pay.order.list(user?.id, event.id));
      data?.length && setAllreadyAddedUsers(data);
      data?.length
        ? setUsers(
          data.map((item) => ({
            login: item.owner.email,
            runet_id: item.owner.runet_id,
            type: String(item.product.id),
            caret_id: item.id,
            id: null,
            flat: null,
          })),
        )
        : setUsers([{ ...JSON.parse(JSON.stringify(defaultUser)) }]);
      data?.length && setOrder_Id(data[0].order_id);
    } catch (e) {
      setUsers([{ ...JSON.parse(JSON.stringify(defaultUser)) }]);
    } finally {
      setLoadingUsers(false);
    }
  }, [setUsers, user, event.id, pay.order]);

  const handleClose = () => {
    setUsers([]);
    setPaymentType(null);
    setStep(0);
    setShow(false);
  };
  const handleShow = useCallback(async () => {
    if (!user) {
      router.push("/auth/login");
      return;
    }
    try {
      await getUserList();
    } finally {
      setShow(true);
    }
  }, [router, user, getUserList]);

  const onAddNew = useCallback(() => {
    setUsers((prev) => [...prev, { ...defaultUser }]);
  }, [setUsers, defaultUser]);

  const removeUser = useCallback(
    (idx: number) =>
      setUsers((prev) => {
        const find = [...prev].find((i, index) => index === idx);
        find?.caret_id && pay.order.del(find?.caret_id);
        const newUsers = Array.from(
          JSON.parse(JSON.stringify([...prev])),
        ).filter((i, index) => index !== idx);
        if (!newUsers.length) {
          return [{ ...JSON.parse(JSON.stringify(defaultUser)) }];
        }
        return newUsers;
      }),
    [setUsers, pay.order],
  );

  useEffect(() => {
    if (!users[users.length - 1]?.login || !users[users.length - 1]?.type) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [users]);

  useEffect(() => {
    const fetchProducts = async () => {
      const filteredProducts = await getFilteredProducts(event);

      setTypeList(filteredProducts);
    };
      fetchProducts();
  }, []);

  return (
    <Col
      xs={{ span: "12", order: 1 }}
      md={{ span: "auto", order: 1 }}
      lg={{ span: "12", order: 1 }}
    >
      <Toaster />
      <Button
        className="sEventReg__btn"
        variant="light"
        onClick={handleShow}
        style={{ position: "relative" }}
      >
        Зарегистрироваться
        {loadingUsers ? (
          <div
            style={{
              position: "absolute",
              display: "inline-flex",
              top: 10,
              right: 10,
            }}
          >
            <Loading />
          </div>
        ) : (
          <></>
        )}
      </Button>

      <Modal className="modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{`Регистрация на мероприятие "${event.title}"`}</Modal.Title>
          <div className="modal__date">
            Дата: {moment(event.start_time).format("DD.MM.YYYY")} -{" "}
            {moment(event.end_time).format("DD.MM.YYYY")}
          </div>
          {step > 1 && (
            <div className="modal__back" onClick={backStep}>
              Назад к выбору способа оплаты
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="nav">
            {step <= 1 && (
              <ul>
                <li className={step === 0 ? "active" : ""}>1. Тип участия</li>
                <li className={step === 1 ? "active" : ""}>2. Способ оплаты</li>
              </ul>
            )}

            <div className={step === 0 ? "nav__item active" : "nav__item"}>
              {users.map((user, idx) => (
                <User
                  key={idx}
                  {...{
                    user,
                    setUsers,
                    idx,
                    typeList,
                    setDisabled,
                    removeUser,
                  }}
                />
              ))}
              <div
                className="form-wrap__add-new"
                onClick={onAddNew}
                style={{
                  marginTop: 25,
                  opacity: disabled ? 0.5 : 1,
                  userSelect: disabled ? "none" : "all",
                  pointerEvents: disabled ? "none" : "all",
                }}
              >
                Добавить участника
              </div>
            </div>
            <div className={step === 1 ? "nav__item active" : "nav__item"}>
              <div className="form-wrap__input-wrap">
                <label>
                  <span className="form-wrap__title">
                    Выберите способ оплаты
                  </span>
                  <Form.Select
                    className="form-wrap__input"
                    onChange={(e) => setPaymentType(e.target.value)}
                    value={paymentType || ""}
                  >
                    <option value="1">Банковской картой онлайн.</option>
                    <option value="2">Счет для юр. лица</option>
                  </Form.Select>
                </label>
              </div>
              <Promo
                value={promo}
                setValue={setPromo}
                order_id={order_id}
                setSummaryWithPromo={setSummaryWithPromo}
                valid={validPromo}
                setValid={setValidPromo}
              />
            </div>
            <div
              className={step === 2 ? "nav__item active" : "nav__item"}
              ref={ref}
            >
              <RegistrationToEventFormForIE
                {...{
                  setFD,
                  delivery_type,
                  setDeliveryType,
                  fd,
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="price">
            {summaryWithPromo === null ? (
              <>
                <span>К оплате:</span> {summary.toLocaleString("ru-RU")} руб.
              </>
            ) : (
              <>
                <span>К оплате:</span>{" "}
                {summaryWithPromo?.toLocaleString("ru-RU")} руб.
                <small className="strike">
                  {" "}
                  {summary.toLocaleString("ru-RU")} руб.
                </small>
              </>
            )}
          </div>
          <RegisterEventActionsBtn
            {...{
              user,
              allreadyAddedUsers,
              users,
              event,
              setOrder_Id,
              setStep,
              setPaymentType,
              paymentType,
              order_id,
              setFD,
              fd,
              delivery_type,
              handleClose,
              setUsers,
              setSummaryWithPromo,
              step,
              disabled,
              summaryWithPromo,
              promo,
              validPromo,
              ref,
            }}
          />
        </Modal.Footer>
      </Modal>
    </Col>
  );
};
