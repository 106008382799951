import { IRegistredUser } from "@/components/event/registerOnEvent";
import { OrderItem } from "@runetid/js-sdk";

export const usePay = () => {
  return {
    coupon: {
      activate: async (code: string, order_id: number) => {
        const response = await fetch(`/api/pay/coupon`, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code, order_id }),
        });

        return await response.json();
      },
    },
    order: {
      butchUpdate: ({ data, eventId }: {
        data: IRegistredUser[],
        eventId: number
      }) => {
        return fetch(`/api/pay/order?event_id=${eventId}`, {
          method: "PUT",
          // mode: 'no-cors',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then(async (res) => {
          return await res.json();
        }).catch(e => {
          console.error("ERROR", e);
        });
      },
      add: (data: {
        product_id: number,
        payer_id: number,
        owner_id: number
      }) => {
        return fetch(`/api/pay/order`, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then(async (res) => {
          return await res.json();
        }).catch(e => {
          console.error("ERROR", e);
        });
      },
      list: (owner_id: number, event_id: number) => {
        return fetch(`/api/pay/order?owner_id=${owner_id}&event_id=${event_id}`, {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async (res) => {
          console.log("res", res);
          const { data } = await res.json();
          return data as OrderItem[];
        });
      },
      del: (id: number) => {
        fetch(`/api/pay/delete?method=order&id=${id}`, {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async (res) => {
          console.log("res", res);
          const data = await res.json();
          console.log("data", data);
          return data;
        });
      },
    },
    process: {
      juridical: (order_id: number, body: any) => {
        return fetch(`/api/pay/juridical?order_id=${order_id}`, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }).then(async (res) => {
          const ss = await res.json();
          return ss.json.data;
        });
      },
      bill: (order_id: number, hash: string) => {
        return fetch(`/api/pay/juridical?order_id=${order_id}&hash=${hash}`, {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async (res) => {
          console.log("res", res);
          return res;
        });
      },
      endPayment: async (order_id: number) => {
        return fetch(`/api/pay/process?order_id=${order_id}`, {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async (res) => {
          console.log("res", res);
          return res;
        });
      },

    },
  };
};
