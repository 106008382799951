// @flow
import * as React from 'react';
import {FC, CSSProperties, useCallback, BaseSyntheticEvent, useEffect, useRef} from 'react';
import {Form} from "react-bootstrap";
import {InputDadataInn} from "../../../components/InputDadataINN";
import InputMask from "react-input-mask";

type IRegistrationToEventFormForIeProps = {
  className?: string
  style?: CSSProperties
  setFD: React.Dispatch<React.SetStateAction<Object>>
  delivery_type: number
  setDeliveryType: React.Dispatch<React.SetStateAction<number>>
  fd: Object
};
export const RegistrationToEventFormForIE: FC<IRegistrationToEventFormForIeProps> = ({
                                                                                       delivery_type,
                                                                                       setDeliveryType, setFD, fd
                                                                                     }) => {
  const ref = useRef(null)
  const setFormData = useCallback((data: any) => {
    //@ts-ignore
    const inputs = ref?.current?.querySelectorAll('input[name]') as NodeList[]
    if (data && inputs.length) {
      for (const item of inputs) {
        //@ts-ignore
        switch (item.getAttribute('name')) {
          case 'name': {
            setFD((prev) => ({...prev, name: data.unrestricted_value}))
            //@ts-ignore
            ref.current.querySelector('input[name="name"]').setAttribute('value', data.unrestricted_value)
            break
          }
          case 'kpp': {
            setFD((prev) => ({...prev, kpp: data.data.kpp}))
            //@ts-ignore
            ref.current.querySelector('input[name="kpp"]').setAttribute('value', data.data.kpp)
            break
          }
          case 'address': {
            setFD((prev) => ({...prev, address: data.data.address.unrestricted_value}))
            //@ts-ignore
            ref.current.querySelector('input[name="address"]').setAttribute('value', data.data.address.unrestricted_value)
            break
          }
          case 'post_address': {
            setFD((prev) => ({...prev, post_address: data.data.address.data.postal_code}))
            //@ts-ignore
            ref.current.querySelector('input[name="post_address"]').setAttribute('value', data.data.address.data.postal_code)
            break
          }
          case 'contact_person': {

              const name = data.data.management == null ? '' : data.data.management.name

            setFD((prev) => ({...prev, contact_person: name}))
            //@ts-ignore
            ref.current.querySelector('input[name="contact_person"]').setAttribute('value', name)
            break
          }
        }
      }
    }
  }, [ref])

  const handlerNumberOnly = (e: BaseSyntheticEvent) => (name: string) => {
    setFD(prev => ({...prev, [name]: e.target.value.replace(/[^0-9]/ig, '')}))
  }

  useEffect(() => {

  }, [ref]);
  return (
    <Form ref={ref} id='regData'>
      <h6>Данные организации</h6>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">
            E-mail или Runet-ID
          </span>
          <Form.Control
            className="form-wrap__input"
            name="contact_email"
          />
        </label>
      </div>

      <h6>Контактные данные</h6>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">
            Название организации
          </span>
          <Form.Control className="form-wrap__input" name="name"/>
        </label>
      </div>
      <InputDadataInn {...{setFormData}}/>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">КПП</span>
          <Form.Control className="form-wrap__input" name="kpp"
            //@ts-ignore
                        value={fd?.['kpp'] || ''}
                        onChange={(e) => handlerNumberOnly(e)('kpp')}/>
        </label>
      </div>

      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Адрес организации</span>
          <Form.Control className="form-wrap__input" name="address"/>
        </label>
      </div>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Почтовый адрес</span>
          <Form.Control
            className="form-wrap__input"
            name="post_address"
            //@ts-ignore
            value={fd?.['post_address'] || ''}
            onChange={(e) => handlerNumberOnly(e)('post_address')}
          />
        </label>
      </div>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Телефон</span>
          <InputMask mask="+7 (999) 999 99 99"
            //@ts-ignore
                     value={fd?.['phone']}>
            {
              //@ts-ignore
              (inputProps) => <Form.Control
                {...inputProps}
                className="form-wrap__input"
                type="tel"
                name={'phone'}
              />}
          </InputMask>
        </label>
      </div>

      <h6>Данные банка</h6>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Название банка</span>
          <Form.Control
            className="form-wrap__input"
            name={'bank_name'}
          />
        </label>
      </div>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">БИК</span>
          <Form.Control className="form-wrap__input" name="bik"
            //@ts-ignore
                        value={fd?.['bik'] || ''}
                        onChange={(e) => handlerNumberOnly(e)('bik')}/>
        </label>
      </div>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">
            Корреспондентский счет
          </span>
          <Form.Control
            className="form-wrap__input"
            name="cor_account"
            //@ts-ignore
            value={fd['cor_account'] || ''}
            onChange={(e) => handlerNumberOnly(e)('cor_account')}
          />
        </label>
      </div>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Расчетный счет</span>
          <Form.Control
            className="form-wrap__input"
            name={'checking_account'}
            //@ts-ignore
            value={fd['checking_account'] || ''}
            onChange={(e) => handlerNumberOnly(e)('checking_account')}
          />
        </label>
      </div>

      <h6>Контактные данные</h6>
      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">
            ФИО контактного лица
          </span>
          <Form.Control
            className="form-wrap__input"
            name={'contact_person'}
          />
        </label>
      </div>

      <div className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Телефон</span>
          <InputMask
            mask="+7 (999) 999 99 99"
            //@ts-ignore
            value={fd?.['fax']}>
            {
              //@ts-ignore
              (inputProps) => <Form.Control
                {...inputProps}
                className="form-wrap__input"
                type="tel"
                name={'fax'}
              />}
          </InputMask>
        </label>
      </div>

      <div className="form-wrap__input-wrap">
        <label>
          <span
            className="form-wrap__title">Как вы хотите получить закрывающие документы по данному счету?</span>
          <Form.Select
            className="form-wrap__input"
            name={'delivery_type'}
            value={delivery_type}
            onChange={(e) => setDeliveryType(Number(e.target.value))}
          >
            <option value={1}>хочу получить на бумаге, на адрес указанный в счете</option>
            <option value={2}>хочу получать по ЭДО</option>
            <option value={3}>иное</option>
          </Form.Select>
        </label>
      </div>
    </Form>
  );
};