import React, { useEffect } from "react";

type PodcastProps = {
  url: string;
  played: string | null,
  setPlayed: (val: string | null) => void
};

export const PodcastBtn = ({ url, played, setPlayed }: PodcastProps) => {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (audioRef.current?.paused) {
      audioRef.current.play();
      setIsPlaying(true);
      setPlayed('audio');
    } else {
      audioRef.current?.pause();
      setIsPlaying(false);
      setPlayed(null);
    }
  };

  useEffect(() => {
    if (played !=='audio' && isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    }
  }, [played, isPlaying]);

  return (
    <button onClick={togglePlay} className="audiofile-btn btn btn-outline-secondary">
      {isPlaying ? (
        <svg
          className="pause"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 512 512"
          height="200px"
          width="200px"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M96 448h106.7V64H96v384zM309.3 64v384H416V64H309.3z"></path>
        </svg>
      ) : (
        <svg
          className="play"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2333_717)">
            <path d="M19.5 12L4.5 20.6603L4.5 3.33974L19.5 12Z" fill="currentColor" />
          </g>
          <defs>
            <clipPath id="clip0_2333_717">
              <rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
      )}
      Аудиоописание
      <audio ref={audioRef} src={url} controls={false}></audio>
    </button>
  );
};