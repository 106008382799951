"use client";

import { Col, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";
import { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import { HallItem } from "./HallItem";
import moment from "moment";
import Sticky from "react-stickynode";
import { generateDateRange } from "@/utils/generateDateRange";
import { ProgramSection } from "./Section";
import { Hall, RunetEvent } from "@runetid/js-sdk";

type ProgramProps = {
  program: Array<RunetEvent>;
  halls: Array<Hall>;
  start_time: string | Date;
  end_time: string | Date;
};

export const Program = ({
                          halls,
                          program,
                          start_time,
                          end_time,
                        }: ProgramProps) => {
  const formatDate = "DD.MM.YYYY";
  const [selectedHall, setSelectedHall] = useState<Hall>(halls[0]);
  const [selectedDay, setSelectedDay] = useState<number>();
  const days = useMemo(
    () => generateDateRange(start_time, end_time),
    [start_time, end_time],
  );
  const daysArrDate = useMemo(
    () => generateDateRange(start_time, end_time, formatDate),
    [start_time, end_time],
  );
  const filtredProgram = useMemo(() => {
    const filtredByDate =
      program.filter(
        (item) =>
          moment(item.start_time, "YYYY-MM-DDTHH:mm:ssZ").utcOffset(0).isSameOrAfter(
            moment(daysArrDate[selectedDay || 0], formatDate).utc(true)
              .hour(0)
              .minute(0)
              .second(0),
          ) &&
          moment(item.end_time, "YYYY-MM-DDTHH:mm:ssZ").utcOffset(0).isSameOrBefore(
            moment(daysArrDate[selectedDay || 0], formatDate).utc(true)
              .hour(23)
              .minute(59)
              .second(0),
          ),
      ) || [];
    const filtredByHall =
      selectedHall &&
      filtredByDate.filter((item) => {
          if (item.places === null) return false;

          return item.places.find((place) => place.id === selectedHall.id);
        },
      );
    if (!filtredByHall?.length && filtredByDate.length > 0) {
      if (filtredByDate[0].places !== null) {
        if (filtredByDate[0].places?.[0] !== null && filtredByDate[0].places?.[0] !== undefined) {
          if (selectedHall !== filtredByDate[0].places?.[0] && filtredByDate[0].places?.[0]) {

            const p = filtredByDate[0].places?.[0];

            if (p) {
              setSelectedHall(p);
            }
          }
        }
      }


      return filtredByDate;
    }
    return filtredByHall;
  }, [selectedHall, program, selectedDay, daysArrDate]);

  const onSelectHall = (hall: Hall) => (e: BaseSyntheticEvent) => {
    setSelectedHall(hall);
  };
  const onDayChange = (e: any) => {
    setSelectedDay(e.activeIndex);
  };

  useEffect(() => {
    //для SEO что бы был весь список мероприятия сразу но после подргузки в клиенте он отфильтруется как нужно по выбранным датам и местам
    setSelectedHall(halls[0]);
    setSelectedDay(0);
  }, [halls]);

  return (
    <section className="sProgram" id="program">
      <h3>Программа мероприятия</h3>
      <Row className="sProgram__control">
        <Col xs={12} sm={12} md={9}>
          <Swiper
            className="sProgram__slider"
            slidesPerView={1}
            spaceBetween={16}
            // onSwiper={(swiper) => console.log(swiper)}
            style={{ display: "flex", rowGap: 15, columnGap: 15 }}
          >
            {halls.map((hall, i) => (
              <HallItem
                key={`hall-${i}`}
                {...{
                  hall,
                  active: selectedHall === hall,
                  onClick: onSelectHall,
                }}
              />
            ))}
          </Swiper>
        </Col>
        <Col xs={12} sm={12} md={3}>
          <div className="sProgram__date">
            <Swiper
              slidesPerView={1}
              navigation={true}
              modules={[Navigation]}
              // className="sProgram__date-slider"
              onSlideChange={onDayChange}
            >
              {days?.map((day, idx) => (
                <SwiperSlide key={`day_${day}_${idx}`}>
                  <span
                    className={idx === selectedDay ? "active" : ""}
                    style={{ userSelect: "none", whiteSpace: "nowrap" }}
                  >
                    {day}
                  </span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Col>
      </Row>
      {/*<div className="sProgram__head">*/}
      {/*  <Row>*/}
      {/*    <Col xl className="sProgram__left-col">*/}
      {/*      <div className="sProgram__live">*/}
      {/*        <span>Прямая трансляция</span>*/}
      {/*      </div>*/}
      {/*    </Col>*/}
      {/*    <Col xl>*/}
      {/*      <h5>*/}
      {/*        Большой шатер*/}
      {/*      </h5>*/}
      {/*      <iframe src="https://vk.com/video_ext.php?oid=-36099097&id=456239274&hd=2" width="853" height="480"*/}
      {/*              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"*/}
      {/*              allowFullScreen></iframe>*/}
      {/*      <h5 className='mt-5'>*/}
      {/*        Шатер Kokoc Group:*/}
      {/*      </h5>*/}
      {/*      <iframe src="https://vk.com/video_ext.php?oid=-36099097&id=456239275&hd=2" width="853" height="480"*/}
      {/*              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"*/}
      {/*              allowFullScreen></iframe>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</div>*/}


      <div
        className="sProgram__body"
        style={{ minHeight: "800px", padding: "12px" }}
      >
        {!selectedHall ? (
          <>
            {program?.map((section, i) => (
              <ProgramSection key={i} section={section} />
            ))}
          </>
        ) : (
          <>
            {filtredProgram?.map((section, i) => (
              <Sticky key={`${i}_${section.id}`} className="sticky-section"
                      top={100}>
                <ProgramSection
                  section={section}
                />
              </Sticky>
            ))}
          </>
        )}
      </div>
    </section>
  );
};
