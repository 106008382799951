"use client";
import React, { Suspense, useState } from "react";
import Image from "next/image";
import moment from "moment/moment";
import { EventDatesBlockProps } from "./EventReg";
import "moment/locale/ru";
import { EventVideoPlayer } from "../components/EventVideoPlayer";
import { PodcastBtn } from "../components/PodcastBtn";
import { Podcast, RunetEvent } from "@runetid/js-sdk";

interface EventHeadProps {
  event: RunetEvent;
  podcasts: Array<Podcast> | null;
}

const EventHead: React.FC<EventHeadProps> = ({
                                               event,
                                               podcasts,
                                             }: EventHeadProps) => {
  const [played, setPlayed] = useState<string | null>(null);

  return (
    <section className="event-head">
      <div className="event-head__container container">
        {/* <Image src={eventBg} alt="bg" className="event-head__bg" /> */}
        <svg
          width="1186"
          height="763"
          viewBox="0 0 1186 763"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="event-head__bg">
          <path
            d="M382.441 762.122L1 382.498L192.737 191.786L382.441 380.624L763.956 1L955.693 191.786L382.441 762.122Z"
            stroke="currentColor"
          />
          <path
            d="M497.066 762.122L115.625 382.498L307.362 191.786L497.066 380.624L878.581 1L1070.32 191.786L497.066 762.122Z"
            stroke="currentColor"
          />
          <path
            d="M611.691 762.122L230.25 382.498L421.987 191.786L611.691 380.624L993.206 1L1184.94 191.786L611.691 762.122Z"
            stroke="currentColor"
          />
        </svg>
        <div className="row">
          <div className="col-lg-6 col-xl-8">
            {/* {event.id_name} */}
            <Suspense fallback={null}>
              <EventDatesBlock start={event.start_time} end={event.end_time} />
            </Suspense>
            <h1>{event.title}</h1>
            <div className="event-head__text"
                 dangerouslySetInnerHTML={{ __html: event.info }}></div>
            {podcasts !== null && podcasts.length > 0 ?
              <PodcastBtn {...{
                url: podcasts[0].url,
                played,
                setPlayed,
              }} /> : ""}
          </div>
          <div className="col-lg-6 col-xl-4">
            <div className="event-head__img-wrap">
              {event.video_source ?
                <EventVideoPlayer {...{ event, played, setPlayed }} /> :
                <>
                  {event.logo_source ? (
                    <Image src={event.logo_source} width={1000} height={1000}
                           alt={event.title} />
                  ) : (
                    <></>
                  )}
                </>
              }

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventHead;

const EventDatesBlock = ({ start, end }: EventDatesBlockProps) => {
  moment.locale("ru");
  const startTime = moment(start);
  const endTime = moment(end);
  const diff = endTime.diff(startTime, "days");

  if (diff < 1) {
    return (
      <div className="event-head__date">
        {startTime.format("D MMMM")}
      </div>
    );
  }

  return (
    <div className="event-head__date">
      {startTime.format("D")} - {endTime.format("D MMMM")}
    </div>
  );
};
