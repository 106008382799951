import axios from "axios";

export const useUsers = () => {
  return {
    list: (search: string, limit: number = 10, offset: number = 0) => {
      return fetch(`/api/user/list`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({search, limit, offset})
      }).then(async (res) => {
        const {data} = await res.json()
        return data
      }).catch(e => {
        console.error('ERROR', e)
      })
    },
    userEventAll: async (user_id: number, page: number, limit: number) => {
      try {
        const {data} = await axios.get(`/api/event/participant?user_id=${user_id}&page=${page}&limit=${limit}`)
        return data
      } catch (e) {
        console.log('e', e)
      }
    },
    userEvent: async (user_id: number, page: number, limit: number)=> {
      try {
        const {data} = await axios.get(`/api/event/participant?user_id=${user_id}&page=${page}&limit=${limit}`)
        return data
      } catch (e) {
        console.log('e', e)
      }
    },
    bill: (participant_id: number) => {
      return fetch(`/api/eticket?id=${participant_id}`, { //TODO добавить сюда урл
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(async (res) => {
        console.log('res', res)
        return res
      })
    }
  }
}