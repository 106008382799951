import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Image from "next/image";
import moment from "moment";
import React, { CSSProperties, Suspense, useState } from "react";
import { EventDatesBlockProps } from "../../EventCard";
import { Participant, RunetEvent } from "@runetid/js-sdk";

type ProgramSectionProps = {
  section: RunetEvent;
  style?: CSSProperties;
  isSticky?: boolean;
};

export const ProgramSection = ({
                                 section,
                                 style,
                                 isSticky,
                               }: ProgramSectionProps) => {
  return (
    <Row className="sProgram__item" style={style}>
      <Col xl className="sProgram__left-col">
        <Suspense fallback={null}>
          <SectionTimeBlock end={section.end_time} start={section.start_time} />
        </Suspense>
      </Col>
      <Col xl>
        <h5>
          {section.full_info == "" ? (
            section.title
          ) : (
            <SectionFullInfo
              text={section.full_info}
              title={section.title}
              participants={section.participants}
            />
          )}
        </h5>
        <p>{section.info}</p>
        <SectionPersons persons={section.participants} limit={null} />
        {/*<SectionsMiniPersons persons={section.participants} limit={null} />*/}
      </Col>
    </Row>
  );
};

type SectionFullInfoProps = {
  text: string;
  title: string;
  participants: Participant[];
};

const SectionFullInfo = ({
                           text,
                           title,
                           participants,
                         }: SectionFullInfoProps) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Modal size="xl" show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "0 0 30px 0 " }}>
            <Col
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Col>
            <Col xs={12}>
              <SectionPersons persons={participants} limit={null} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <div className={"qr_lk hover-green"} onClick={() => setShow(true)}>
        {title}
      </div>
    </>
  );
};

const SectionTimeBlock = ({ start, end }: EventDatesBlockProps) => {
  moment.locale("ru");
  const startTime = moment(start).add(-3, "hours");
  const endTime = moment(end).add(-3, "hours");

  return (
    <div className="sProgram__time" data-s={start} data-e={end}>
      {startTime.format("HH:mm")} – {endTime.format("HH:mm")}
    </div>
  );
};

type PersonsProps = {
  persons: Participant[];
  limit: number | null;
};

const SectionsMiniPersons = ({ persons }: PersonsProps) => {
  const personsArray = persons?.slice(4, persons.length);
  if (personsArray?.length < 1) {
    return null;
  }
  return (
    <Row className="sProgram__avatars">
      {personsArray?.map((person, i) => {
        return (
          <Col xs="auto" key={i}>
            <Image
              src={person.user.photo}
              alt="Person"
              width={50}
              height={50}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const SectionPersons = ({ persons, limit = 4 }: PersonsProps) => {
  let personsArray: Participant[];
  if (limit != null) {
    personsArray = persons?.slice(0, limit);
  } else {
    personsArray = persons;
  }

  if (personsArray?.length < 1) {
    return null;
  }

  return (
    <Row className="sProgram__person-row" style={{ columnGap: 5 }}>
      {personsArray?.map((person, i) => {
        return (
          <Col
            md={3}
            key={i}
            className="sProgram__person"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <div
              className="sProgram__img-wrap"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={person.user.photo}
                alt="Person"
                width={100}
                height={100}
              />
            </div>
            <div>
              <div className="sProgram__name">
                <span>{person.user.first_name}</span>{" "}
                <span>{person.user.last_name}</span>
              </div>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip
                  id="button-tooltip-2">{person.role.title}</Tooltip>}
              >
                <div className="sProgram__status">{person.role.title}</div>
              </OverlayTrigger>
              {/*<div className="sProgram__place">АО Лужники</div>*/}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
