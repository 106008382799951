import { Product, RunetEvent } from "@runetid/js-sdk";

export const useProduct = () => {
  const getListTypes = async (event: RunetEvent, cb: any) => {
    fetch(`/api/products/list/${event.id}`, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const { data } = await res.json();
      cb((data as Product[]).sort((a, b) => a.current_price.cost - b.current_price.cost));
    });
  };

  const getFilteredProducts = async (event: RunetEvent): Promise<Product[]> => {
    return new Promise((resolve, reject) => {
      getListTypes(event, async (products: Product[]) => {
        try {
          const filteredProducts = await Promise.all(
            products.map(async (product) => {
              if (product.count === null) {
                return product;
              }
              const response = await fetch(`/api/pay/statistic/product/${product.id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                return null;
              }

              const { paid, reserve } = await response.json();
              if (paid + reserve >= product.count) {
                return null;
              }

              return product;
            }),
          );

          resolve(filteredProducts.filter((product) => product !== null) as Product[]);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    });
  };

  return {
    getListTypes,
    getFilteredProducts,
  };
};

