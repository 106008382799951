import moment from 'moment'
import 'moment/locale/ru'

export function generateDateRange(
  startDate: string | Date,
  endDate: string | Date,
  returnedFormat: string = 'DD MMM',
) {
  let dates = []
  let currentDate = moment(startDate).locale('ru')

  while (currentDate <= moment(endDate).locale('ru')) {
    dates.push(currentDate.format(returnedFormat))
    currentDate.add(1, 'days')
  }

  return dates
}
