import { useEffect, useState } from "react";
import Script from "next/script";
import { Button } from "react-bootstrap";
import * as React from "react";
import toast from "react-hot-toast";

type CloudPaymentProps = {
  orderId: number;
  disabled?: boolean;
  title?: string;
  beforeSuccessCallback: Function;
};

export const CloudPayments = ({
                                orderId,
                                title,
                                beforeSuccessCallback = async () => {
                                },
                              }: CloudPaymentProps) => {
  const [payOptions, setPayOptions] = useState({});

  const cloudPaymentsPayHandler = async function() {
    await beforeSuccessCallback();
    // @ts-ignore
    const widget = new cp.CloudPayments();
    widget.charge(
      payOptions,
      function(options: any) {
        //todo: open success modal
        toast("Оплата прошла удачно");
      },
      function(reason: any, options: any) {
        //todo: open error modal
        console.warn("cancel payment reason", reason);
        toast.error("Оплата не прошла ");
      },
    );
  };

  useEffect(() => {
    fetch("/api/pay/gateway?order_id=" + orderId, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((data) => data?.json())
      .then((options) => {
        options?.json && setPayOptions(options?.json);
      });
  }, [orderId]);

  return (
    <>
      <Script
        src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"></Script>
      <Button
        className="w-100 h-100"
        variant="primary"
        onClick={cloudPaymentsPayHandler}
      >
        {title}
      </Button>
    </>
  );
};
