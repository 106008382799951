// @flow
import * as React from 'react';
import {FC, CSSProperties} from 'react';
import {ClipLoader} from "react-spinners";

type ILoadingProps = {
    className?: string
    style?: CSSProperties
};
export const Loading: FC<ILoadingProps> = ({style}) => {
    return (
        <ClipLoader color="#36d7b7" style={{
            width: 35,
            height: 35,
            borderRadius: '100%',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'rgb(54, 215, 183) rgb(54, 215, 183) transparent',
            borderImage: 'initial',
            display: 'inline-block',
            animation: '0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip',
            ...style
        }}/>
    );
};