"use client";

import { Suspense, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Link from "next/link";
import moment from "moment";
import "moment/locale/ru";
import { SimpleReg } from "@/components/event/simpleReg";
import { RegisterOnEvent } from "@/components/event/registerOnEvent";
import { RunetEvent, User } from "@runetid/js-sdk";

type EventRegPros = {
  event: RunetEvent
  user: User | null
}

const EventReg = ({ event, user }: EventRegPros) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const offsetTop = document.querySelector(".event-head")?.scrollHeight;
      // You can adjust the threshold as needed
      if (offset > (offsetTop || 100)) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`sEventReg ${isSticky ? "sticky" : ""}`}>
      <Suspense fallback={null}>
        <EventDatesBlock start={event.start_time} end={event.end_time} />
      </Suspense>
      <Row className="sEventReg__middle-row">
        {/*<Col xs="6" md lg="6">*/}
        {/*  <div className="sEventReg__item">*/}
        {/*    <span>Стоимость:</span>*/}
        {/*    Бесплатно*/}
        {/*  </div>*/}
        {/*</Col>*/}
        {/*<Col xs="6" md lg="6">*/}
        {/*  <div className="sEventReg__item">*/}
        {/*    <span>Формат:</span>*/}
        {/*    Офлайн*/}
        {/*  </div>*/}
        {/*</Col>*/}

        {event.places !== undefined &&
        event.places != null &&
        event.places.length > 0 ? (
          <Col xs="12" md lg="12">
            <div className="sEventReg__item">
              <span>Адрес:</span>
              {event.places[0].title}
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <Row className="sEventReg__bottom-row">


        <RegButton event={event} user={user} />

        <Col
          xs={{ span: "12", order: 2 }}
          md={{ span: "auto", order: 4 }}
          lg={{ span: "12", order: 2 }}
        >
          <Suspense fallback={null}>
            <RegEndBlock end={event.end_time} />
          </Suspense>
        </Col>
        <Col
          xs={{ order: 3 }}
          md={{ span: "auto", order: 2 }}
          lg={{ order: 3 }}
          className="col ms-md-auto ms-lg-0"
        >
          <Link
            href={`/api/event/event.ics?event=${event.id_name}`}
            target="_blank"
            className="sEventReg__bottom-btn btn btn-dark"
          >
            <svg
              className="sEventReg__bottom-icon"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.4173 1.17578H15.6526V2.94049C15.6526 3.29343 15.3585 3.52872 15.0643 3.52872C14.7702 3.52872 14.4761 3.29343 14.4761 2.94049V1.17578H5.06434V2.94049C5.06434 3.29343 4.77022 3.52872 4.4761 3.52872C4.18199 3.52872 3.88787 3.29343 3.88787 2.94049V1.17578H2.12316C1.24081 1.17578 0.59375 1.94049 0.59375 2.94049V5.05813H19.4173V2.94049C19.4173 1.94049 18.3585 1.17578 17.4173 1.17578ZM0.59375 6.29343V17.0581C0.59375 18.117 1.24081 18.8228 2.18199 18.8228H17.4761C18.4173 18.8228 19.4761 18.0581 19.4761 17.0581V6.29343H0.59375ZM5.82904 16.1758H4.41728C4.18199 16.1758 3.94669 15.9993 3.94669 15.7052V14.2346C3.94669 13.9993 4.12316 13.764 4.41728 13.764H5.88787C6.12316 13.764 6.35846 13.9405 6.35846 14.2346V15.7052C6.29963 15.9993 6.12316 16.1758 5.82904 16.1758ZM5.82904 10.8817H4.41728C4.18199 10.8817 3.94669 10.7052 3.94669 10.4111V8.94049C3.94669 8.70519 4.12316 8.4699 4.41728 8.4699H5.88787C6.12316 8.4699 6.35846 8.64637 6.35846 8.94049V10.4111C6.29963 10.7052 6.12316 10.8817 5.82904 10.8817ZM10.5349 16.1758H9.06434C8.82904 16.1758 8.59375 15.9993 8.59375 15.7052V14.2346C8.59375 13.9993 8.77022 13.764 9.06434 13.764H10.5349C10.7702 13.764 11.0055 13.9405 11.0055 14.2346V15.7052C11.0055 15.9993 10.829 16.1758 10.5349 16.1758ZM10.5349 10.8817H9.06434C8.82904 10.8817 8.59375 10.7052 8.59375 10.4111V8.94049C8.59375 8.70519 8.77022 8.4699 9.06434 8.4699H10.5349C10.7702 8.4699 11.0055 8.64637 11.0055 8.94049V10.4111C11.0055 10.7052 10.829 10.8817 10.5349 10.8817ZM15.2408 16.1758H13.7702C13.5349 16.1758 13.2996 15.9993 13.2996 15.7052V14.2346C13.2996 13.9993 13.4761 13.764 13.7702 13.764H15.2408C15.4761 13.764 15.7114 13.9405 15.7114 14.2346V15.7052C15.7114 15.9993 15.5349 16.1758 15.2408 16.1758ZM15.2408 10.8817H13.7702C13.5349 10.8817 13.2996 10.7052 13.2996 10.4111V8.94049C13.2996 8.70519 13.4761 8.4699 13.7702 8.4699H15.2408C15.4761 8.4699 15.7114 8.64637 15.7114 8.94049V10.4111C15.7114 10.7052 15.5349 10.8817 15.2408 10.8817Z"
                fill="currentColor"
              />
            </svg>
            Добавить в календарь
          </Link>
        </Col>
        {/*<Col*/}
        {/*  xs={{ span: 'auto', order: 4 }}*/}
        {/*  md={{ span: 'auto', order: 3 }}*/}
        {/*  lg={{ span: 'auto', order: 4 }}*/}
        {/*  className="ms-auto ms-md-0 ms-lg-auto">*/}
        {/*  {event.site_url != '' ? (*/}
        {/*    <Link className="sEventReg__bottom-btn btn btn-dark" href={event.site_url}>*/}
        {/*      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*        <g clipPath="url(#clip0_2333_786)">*/}
        {/*          <path*/}
        {/*            d="M10.5938 4.74203C4.72637 5.04758 0.0078125 9.91762 0.0078125 15.8594V20L1.48926 16.5512C3.24137 13.0476 6.71766 10.8074 10.5938 10.6014V15.3404L19.9941 7.65625L10.5938 0V4.74203Z"*/}
        {/*            fill="white"*/}
        {/*          />*/}
        {/*        </g>*/}
        {/*        <defs>*/}
        {/*          <clipPath id="clip0_2333_786">*/}
        {/*            <rect width="20" height="20" fill="white" />*/}
        {/*          </clipPath>*/}
        {/*        </defs>*/}
        {/*      </svg>*/}
        {/*    </Link>*/}
        {/*  ) : (*/}
        {/*    ''*/}
        {/*  )}*/}
        {/*</Col>*/}
      </Row>
    </div>
  );
};

type RegButtonProps = {
  event: RunetEvent
  user: User | null
}

const RegButton = ({ event, user }: RegButtonProps) => {

  if (event.id === 11160) {
    return (<></>);
  }

  if (event.external) {
    return (<a href={event.site_url} className="sEventReg__btn btn btn-light"
               target="_blank">
      Зарегистрироваться
    </a>);
  }

  if (event.paid) {
    return (<RegisterOnEvent {...{ event, user }} />);
  }

  return (<SimpleReg  {...{ event, user }} />);
};

export default EventReg;

type RegEndBlockProps = {
  end: string
}
const RegEndBlock = ({ end }: RegEndBlockProps) => {
  const endTime = moment(end);
  const now = moment();
  const diff = endTime.diff(now, "days");

  if (diff <= 0) {
    return "";
  }

  return (
    <div className="sEventReg__end-time">
      До конца регистрации:
      <span>{diff > 0 ? diff + "д. " : ""}</span>
    </div>
  );
};

export type EventDatesBlockProps = {
  start: string
  end: string
}

const EventDatesBlock = ({ start, end }: EventDatesBlockProps) => {
  moment.locale("ru");
  const startTime = moment(start);
  const endTime = moment(end);
  const diff = endTime.diff(startTime, "days");

  if (diff < 1) {
    return (
      <h4 className="sEventReg__date">
        {startTime.format("D MMMM")}
        <span>
          {startTime.format("HH:mm")} – {endTime.format("HH:mm")}{" "}
        </span>
      </h4>
    );
  }

  return (
    <h4 className="sEventReg__date">
      {startTime.format("D")} - {endTime.locale("ru").format("D MMMM")}
      <span>
        {startTime.format("HH:mm")} – {endTime.format("HH:mm")}{" "}
      </span>
    </h4>
  );
};
