// @flow
"use client";
import * as React from "react";
import {
  FC,
  CSSProperties,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Form } from "react-bootstrap";
import {
  IRegistredUser,
} from "../../../components/event/registerOnEvent/index";
import { useUsers } from "../../../hooks/useUsers";
import { Loading } from "../../../components/Loading";
import { Remove } from "../../../components/remove";
import { Product, User as UserModel } from "@runetid/js-sdk";

type IUserProps = {
  className?: string
  style?: CSSProperties
  idx: number
  user: IRegistredUser
  setUsers: React.Dispatch<React.SetStateAction<IRegistredUser[]>>
  typeList: Product[]
  setDisabled: (val: boolean) => void
  removeUser: (idx: number) => void
}

export const User: FC<IUserProps> = ({
                                       user,
                                       idx,
                                       setUsers,
                                       typeList,
                                       setDisabled,
                                       removeUser,
                                     }) => {

  const [login, setLogin] = useState(user.login);
  const [type, setType] = useState(user.type || String(typeList[0].id));
  const [error, setError] = useState<string | null>(null);
  const [ulist, setUlist] = useState<UserModel | null>();
  const [loading, setLoading] = useState(false);
  const id = useMemo(() => ulist?.id, [ulist]);
  const runet_id = useMemo(() => ulist?.runet_id, [ulist]);
  const { list } = useUsers();

  const regID = useMemo(() => /[0-9]{2,10}/, []);
  const regEmail = useMemo(() => /^(?=.{1,64}@)[A-Za-z0-9_.+-]+(\.[A-Za-z0-9_.+-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/, []);

  const getList = useCallback(
    async (login: string) => {
      try {
        setLoading(true);
        setDisabled(true);
        const usersList = await list(login, 1, 0);
        if (!usersList.length) {
          setError("Не найдена почта или RunetID");
          setUlist(null);
          return;
        }
        setUlist(usersList[0]);
        setError(null);
      } catch (e) {
        setError("Не найдена почта или RunetID");
        setUlist(null);
      } finally {
        setLoading(false);
        setDisabled(false);
      }
    },
    [list, setDisabled],
  );

  useEffect(() => {
    const time = setTimeout(async () => {
      login.length > 2 && (await getList(login));
    }, 2000);
    return () => {
      clearTimeout(time);
    };
  }, [login]);

  useEffect(() => {
    if (!regID.test(login) && !regEmail.test(login)) {
      setError("Не корректная почта или ID");
    }
    setError(null);
    setUsers((prev) => {
      const newArr = [...prev];
      newArr[idx] = { ...prev[idx], login };
      return newArr;
    });
  }, [login, idx]);

  useEffect(() => {
    setUsers((prev) => {
      const newArr = [...prev];
      newArr[idx] = { ...prev[idx], type };
      return newArr;
    });
  }, [type, idx]);

  useEffect(() => {
    setUsers((prev) => {
      const newArr = [...prev];
      newArr[idx] = {
        ...prev[idx],
        id: id ?? null,
        runet_id: runet_id ?? null,
      };
      return newArr;
    });
  }, [id, runet_id, idx, ulist]);

  useEffect(() => {
    if (login !== user.login) {
      setLogin(user.login);
    }
    if (type !== user.type) {
      user.type && setType(user.type);
    }
    if (id !== user.id && runet_id !== user.runet_id) {
      //@ts-ignore
      setUlist({ id: user.id, runet_id: user.runet_id });
    }
  }, []);

  useEffect(() => {
    login !== user.login && setLogin(user.login);
    login !== user.login && setUlist(null);
  }, [user.login]);

  useEffect(() => {
    type !== user.type && setType(user.type || "1");
    type !== user.type && setUlist(null);
  }, [user.type]);

  const allTypesUnavailable = useMemo(
    () => typeList.length !== 0,//.every((item) => item.count === 0 || item.count === null),
    [typeList]
  );

  return (
    <>
      <Form.Group className="form-wrap__input-wrap">
        <label>
          {/* <span className="form-wrap__title">E-mail</span> */}
          <div style={{ display: "flex", columnGap: 10 }}>
            <Form.Control
              className="form-wrap__input"
              type="text"
              placeholder="E-mail или Runet-ID*"
              onChange={(e) => setLogin(e.target.value)}
              value={login}
              required
              isInvalid={!!error}
              isValid={!!(user.id && user.runet_id)}
              disabled={loading}
              autoComplete={"off"}
            />
            {/*<Form.Control.Feedback type="valid" tooltip>*/}
            {/*  {ulist?.email}*/}
            {/*</Form.Control.Feedback>*/}
            <Form.Control.Feedback type="invalid" tooltip>
              {error}
            </Form.Control.Feedback>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: 12,
                  right: 50,
                }}
              >
                <Loading />
              </div>
            ) : (
              <></>
            )}
            <Remove onClick={() => removeUser(idx)} />
          </div>
        </label>
      </Form.Group>
      {allTypesUnavailable && (
      <Form.Group className="form-wrap__input-wrap">
        <label>
          <span className="form-wrap__title">Выберите тип участия*</span>
          <Form.Select
            className="form-wrap__input"
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            {typeList
              ?.sort((a, b) => a.current_price.cost - b.current_price.cost)
              .map((item, idx) => (
                <option value={item.id} key={`${item.id}_${idx}`}>
                  {`${item.title} ${item.current_price.cost.toLocaleString("ru-RU")} руб.`}
                </option>
              ))}
          </Form.Select>
        </label>
      </Form.Group>
      )}
      {/*<Form.Group className="form-wrap__input-wrap">*/}
      {/*    {!addNew && (*/}
      {/*        <div className="form-wrap__add-new" onClick={() => setAddNew(true)}>*/}
      {/*            Добавить номер в гостинице*/}
      {/*        </div>*/}
      {/*    )}*/}
      {/*    {addNew && (*/}
      {/*        <label>*/}
      {/*            <span className="form-wrap__title">Выберите тип номера</span>*/}
      {/*            <Form.Select className="form-wrap__input">*/}
      {/*                <option value="1">Двухместный стандарт, 5 000 руб..</option>*/}
      {/*                <option value="2">Двухместный стандарт, 5 000 руб.</option>*/}
      {/*                <option value="3">Двухместный стандарт, 5 000 руб.</option>*/}
      {/*                <option value="4">Двухместный стандарт, 5 000 руб.</option>*/}
      {/*            </Form.Select>*/}
      {/*            <div className="form-wrap__caption">Размещение в отеле METROPOL</div>*/}
      {/*        </label>*/}
      {/*    )}*/}
      {/*</Form.Group>*/}
    </>
  );
};
